@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;


  svg {
    height: 100%;
  }
}

.spacer {
  height: 100%;
}

.bubble-wrap {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.colors-wrap {
  height: 100%;
  width: 300px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  ul {
    list-style: none;
    text-align: start;

    li {
      cursor: pointer;
      padding: 6px 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.delete {
  color: rgb(216, 87, 87);
}

.color-box {
  height: 16px;
  width: 16px;
  border: 1px lightgray solid;
  margin-right: 36px;
}

form {
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  legend {
    padding-left: 40px;
    font-weight: bold;
    text-align: left;
    text-decoration: underline;
    margin-bottom: 16px;
  }

  label {
    display: flex;
    justify-content: space-between;
    padding: 4px 36px 4px 40px;
  }

  input {
    width: 100px;
  }
}

.button-row {
  display: flex;
  justify-content: space-between;
  padding: 0 36px 0 41px;

  button {
    border: none;
    background-color: black;
    color: white;
    padding: 3px 8px;
    margin-top: 16px;
  }
}

.log-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

h2 {
  padding: 2%;
}
.logins {
  width: 200px;
  margin-top: 1%;
}

.log-sub {
  margin: 2%;
}
.bubbles {
  background-image: url('https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/fb07af9e-a4d5-4f2a-8e48-4fd3da8229c0/d30cl83-eb74c6dc-dd8d-467a-ac40-9d8c85ff4d34.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2ZiMDdhZjllLWE0ZDUtNGYyYS04ZTQ4LTRmZDNkYTgyMjljMFwvZDMwY2w4My1lYjc0YzZkYy1kZDhkLTQ2N2EtYWM0MC05ZDhjODVmZjRkMzQucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.htc0Mfm5qD0hLwZogVmJkv6mr1MBdL0O114FRSXsEI0');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: .8;
}